import React, { useContext, useEffect, useState } from "react";
import ChartComp from "./Chart";
import RecentRegistered from "./RecentRegistered";
import AuthContext from "../context/AuthContext";
import { toast } from "wc-toast";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { Container, Row, Col, Form, Table, Button } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import helpers from "../utils/helpers";
const Dashboard = () => {
  const { user } = useContext(AuthContext);
  const [registeredUserData, setRegistedredUserData] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
  });

  function copy(text) {
    toast.success("Link Copied!");
    return window.navigator.clipboard.writeText(text);
  }
  const [profileData, setProfileData] = useState({});
  const myProfile = async () => {
    const { status, data: response_users } = await apiGet(apiPath.userProfile);
    if (status === 200) {
      if (response_users.success) {
        setProfileData(response_users.results);
      }
    }
  };

  const getRecentRegisteredUser = async (obj = filter) => {
    const { status, data: response_users } = await apiGet(
      apiPath.recentRegisteredUser,
      {
        page: obj?.page || 1,
        limit: obj?.limit,
      }
    );
    if (status === 200) {
      if (response_users.success) {
        setRegistedredUserData(response_users?.results);
      }
    }
  };

  useEffect(() => {
    myProfile();
    getRecentRegisteredUser();
  }, []);


  return (
    <div>
      {user?.userType == "agent" && (
        <div className="d-flex align-items-start">
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              paddingTop: "10px",
              fontSize: "15px",
              border: "#ccc thin solid",
              background: "#fff",
              marginTop: "15px",
              marginLeft: "22px",
              borderRadius: "20px",
              padding: "5px 0",
              paddingLeft: "0px",
            }}
          >
            <span style={{ paddingLeft: "12px" }}>User Referal Code : </span>
            <span
              style={{
                cursor: "pointer",
                color: "#fff",
                paddingLeft: "5px",
                background: "green",
                borderRadius: "20px",
                padding: "5px 20px",
                margin: "0 5px",
              }}
              onClick={() => {
                copy(
                  "https://melbets.live/login?referral_code=" +
                  profileData?.referalCode
                );
              }}
              className="text-start"
            >
              {profileData?.referalCode
                ? " https://melbets.live/login?referral_code=" +
                profileData?.referalCode
                : "-"}
            </span>
          </div>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              paddingTop: "10px",
              fontSize: "15px",
              border: "#ccc thin solid",
              background: "#fff",
              marginTop: "15px",
              marginLeft: "22px",
              borderRadius: "20px",
              padding: "5px 0",
              paddingLeft: "0px",
            }}
          >
            <span style={{ paddingLeft: "12px" }}>Affiliate Referal Code : </span>
            <span
              style={{
                cursor: "pointer",
                color: "#fff",
                paddingLeft: "5px",
                background: "green",
                borderRadius: "20px",
                padding: "5px 20px",
                margin: "0 5px",
              }}
              onClick={() => {
                copy(
                  "https://melbets.live/affilate?referral_code=" +
                  profileData?.referalCode
                );
              }}
              className="text-start"
            >
              {profileData?.referalCode
                ? " https://melbets.live/affilate?referral_code=" +
                profileData?.referalCode
                : "-"}
            </span>
          </div>
        </div>
      )}
      {/* <ChartComp /> */}

      {/* <RecentRegistered /> */}
      <div class="col-wrap panel-group">
        <div class="section col-2 panel">
          <div class="section-body">
            <h4>Commission</h4>
            <div class="float-right">
              <div class="form-group">
                <h4>This Period</h4>
                <p class="number" id="thisPeriodCommission">৳ {registeredUserData?.activePlayerData?.thisWeek?.count || 0}</p>
              </div>
              <div class="form-group">
                <h4>Last Period</h4>
                <p class="number" id="lastPeriodCommission">৳  {registeredUserData?.activePlayerData?.lastWeek?.count || 0}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="section col-2 panel">
          <div class="section-body">
            <h4>Active Players
            </h4>
            <div class="float-right">
              <div class="form-group">
                <h4>This Period</h4>
                <p class="number" id="thisPeriodActivePlayer">{registeredUserData?.commissionData?.thisWeek || 0}</p>
              </div>
              <div class="form-group">
                <h4>Last Period</h4>
                <p class="number" id="lastPeriodActivePlayer">{registeredUserData?.commissionData?.lastWeek || 0}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-wrap">
        <div class="section col-2 panel" id="section_2">

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Registered Users</Accordion.Header>
              <Accordion.Body>
                <Table className="table-view">
                  <thead>
                  <tr>
                       
                        <th className="text-center" scope="col">
                          Period
                        </th>
                        <th className="text-center" scope="col">
                          Count
                        </th>
                        
                      </tr>
                  </thead>

                  {registeredUserData?.userCounts && registeredUserData?.userCounts?.length > 0 ? (
                      registeredUserData?.userCounts?.map((item, index) => {
                        return (
                          <tr>
                           
                            <td className="text-center">{item?.period}</td>
                            <td className="text-center">{item?.count}</td>
                            
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <span>You have no data in this time period.</span>
                        </td>
                      </tr>
                    )}




                </Table>
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
        </div>
        <div class="section col-2 panel" id="section_2">

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>First Deposit</Accordion.Header>
              <Accordion.Body>
                <Table className="table-view">
                  <thead>
                    <tr>
                      <th className="" scope="col">
                       Period
                      </th>
                      <th className="text-center" scope="col">
                       Count
                      </th>
                      <th className="text-center" scope="col">
                       Amount
                      </th>
                     
                    </tr>
                  </thead>
                  
                  {registeredUserData?.firstDepositUsers  ? (
                    <>
                  <tr>
                           <td className="text-center">Today</td>
                            <td className="text-center">{registeredUserData?.firstDepositUsers?.today?.count}</td>
                            <td className="text-center">{registeredUserData?.firstDepositUsers?.today?.amount}</td>
                            
                          </tr>
                          <tr>
                          <td className="text-center">Yesterday</td>
                           <td className="text-center">{registeredUserData?.firstDepositUsers?.yesterday?.count}</td>
                           <td className="text-center">{registeredUserData?.firstDepositUsers?.yesterday?.amount}</td>
                           
                         </tr>
                         <tr>
                         <td className="text-center">This Week</td>
                          <td className="text-center">{registeredUserData?.firstDepositUsers?.thisWeek?.count}</td>
                          <td className="text-center">{registeredUserData?.firstDepositUsers?.thisWeek?.amount}</td>
                          
                        </tr>
                        <tr>
                        <td className="text-center">Last Week</td>
                         <td className="text-center">{registeredUserData?.firstDepositUsers?.lastWeek?.count}</td>
                         <td className="text-center">{registeredUserData?.firstDepositUsers?.lastWeek?.amount}</td>
                         
                       </tr>
                       <tr>
                       <td className="text-center">This Month</td>
                        <td className="text-center">{registeredUserData?.firstDepositUsers?.thisMonth?.count}</td>
                        <td className="text-center">{registeredUserData?.firstDepositUsers?.thisMonth?.amount}</td>
                        
                      </tr>
                      <tr>
                      <td className="text-center">Last Month</td>
                       <td className="text-center">{registeredUserData?.firstDepositUsers?.lastMonth?.count}</td>
                       <td className="text-center">{registeredUserData?.firstDepositUsers?.lastMonth?.amount}</td>
                       
                     </tr>
                     </>
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <span>You have no data in this time period.</span>
                        </td>
                      </tr>
                    )}
                </Table>
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
        </div>
      </div>
      <div class="col-wrap">
        <div class="section col-2 panel" id="section_2">

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Deposit</Accordion.Header>
              <Accordion.Body>
              <Table className="table-view">
                  <thead>
                    <tr>
                      <th className="" scope="col">
                       Period
                      </th>
                      <th className="text-center" scope="col">
                       Count
                      </th>
                      <th className="text-center" scope="col">
                       Amount
                      </th>
                     
                    </tr>
                  </thead>
                  
                  {registeredUserData?.last10Deposits  ? (
                    <>
                  <tr>
                           <td className="text-center">Today</td>
                            <td className="text-center">{registeredUserData?.last10Deposits?.today?.count}</td>
                            <td className="text-center">{registeredUserData?.last10Deposits?.today?.amount}</td>
                            
                          </tr>
                          <tr>
                          <td className="text-center">Yesterday</td>
                           <td className="text-center">{registeredUserData?.last10Deposits?.yesterday?.count}</td>
                           <td className="text-center">{registeredUserData?.last10Deposits?.yesterday?.amount}</td>
                           
                         </tr>
                         <tr>
                         <td className="text-center">This Week</td>
                          <td className="text-center">{registeredUserData?.last10Deposits?.thisWeek?.count}</td>
                          <td className="text-center">{registeredUserData?.last10Deposits?.thisWeek?.amount}</td>
                          
                        </tr>
                        <tr>
                        <td className="text-center">Last Week</td>
                         <td className="text-center">{registeredUserData?.last10Deposits?.lastWeek?.count}</td>
                         <td className="text-center">{registeredUserData?.last10Deposits?.lastWeek?.amount}</td>
                         
                       </tr>
                       <tr>
                       <td className="text-center">This Month</td>
                        <td className="text-center">{registeredUserData?.last10Deposits?.thisMonth?.count}</td>
                        <td className="text-center">{registeredUserData?.last10Deposits?.thisMonth?.amount}</td>
                        
                      </tr>
                      <tr>
                      <td className="text-center">Last Month</td>
                       <td className="text-center">{registeredUserData?.last10Deposits?.lastMonth?.count}</td>
                       <td className="text-center">{registeredUserData?.last10Deposits?.lastMonth?.amount}</td>
                       
                     </tr>
                     </>
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <span>You have no data in this time period.</span>
                        </td>
                      </tr>
                    )}
                </Table>
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
        </div>
        <div class="section col-2 panel" id="section_2">

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Withdrawal</Accordion.Header>
              <Accordion.Body>
              <Table className="table-view">
                  <thead>
                    <tr>
                      <th className="" scope="col">
                       Period
                      </th>
                      <th className="text-center" scope="col">
                       Count
                      </th>
                      <th className="text-center" scope="col">
                       Amount
                      </th>
                     
                    </tr>
                  </thead>
                  
                  {registeredUserData?.last10Withdrawals  ? (
                    <>
                  <tr>
                           <td className="text-center">Today</td>
                            <td className="text-center">{registeredUserData?.last10Withdrawals?.today?.count}</td>
                            <td className="text-center">{registeredUserData?.last10Withdrawals?.today?.amount}</td>
                            
                          </tr>
                          <tr>
                          <td className="text-center">Yesterday</td>
                           <td className="text-center">{registeredUserData?.last10Withdrawals?.yesterday?.count}</td>
                           <td className="text-center">{registeredUserData?.last10Withdrawals?.yesterday?.amount}</td>
                           
                         </tr>
                         <tr>
                         <td className="text-center">This Week</td>
                          <td className="text-center">{registeredUserData?.last10Withdrawals?.thisWeek?.count}</td>
                          <td className="text-center">{registeredUserData?.last10Withdrawals?.thisWeek?.amount}</td>
                          
                        </tr>
                        <tr>
                        <td className="text-center">Last Week</td>
                         <td className="text-center">{registeredUserData?.last10Withdrawals?.lastWeek?.count}</td>
                         <td className="text-center">{registeredUserData?.last10Withdrawals?.lastWeek?.amount}</td>
                         
                       </tr>
                       <tr>
                       <td className="text-center">This Month</td>
                        <td className="text-center">{registeredUserData?.last10Withdrawals?.thisMonth?.count}</td>
                        <td className="text-center">{registeredUserData?.last10Withdrawals?.thisMonth?.amount}</td>
                        
                      </tr>
                      <tr>
                      <td className="text-center">Last Month</td>
                       <td className="text-center">{registeredUserData?.last10Withdrawals?.lastMonth?.count}</td>
                       <td className="text-center">{registeredUserData?.last10Withdrawals?.lastMonth?.amount}</td>
                       
                     </tr>
                     </>
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <span>You have no data in this time period.</span>
                        </td>
                      </tr>
                    )}
                </Table>
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
        </div>
      </div>
      <div class="col-wrap">
        <div class="section col-2 panel" id="section_2">

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Bonus</Accordion.Header>
              <Accordion.Body>
              <Table className="table-view">
                  <thead>
                    <tr>
                      <th className="" scope="col">
                       Period
                      </th>
                      <th className="text-center" scope="col">
                       Count
                      </th>
                      <th className="text-center" scope="col">
                       Amount
                      </th>
                     
                    </tr>
                  </thead>
                  
                  {registeredUserData?.last10Bonuses  ? (
                    <>
                  <tr>
                           <td className="text-center">Today</td>
                            <td className="text-center">{registeredUserData?.last10Bonuses?.today?.count}</td>
                            <td className="text-center">{registeredUserData?.last10Bonuses?.today?.amount}</td>
                            
                          </tr>
                          <tr>
                          <td className="text-center">Yesterday</td>
                           <td className="text-center">{registeredUserData?.last10Bonuses?.yesterday?.count}</td>
                           <td className="text-center">{registeredUserData?.last10Bonuses?.yesterday?.amount}</td>
                           
                         </tr>
                         <tr>
                         <td className="text-center">This Week</td>
                          <td className="text-center">{registeredUserData?.last10Bonuses?.thisWeek?.count}</td>
                          <td className="text-center">{registeredUserData?.last10Bonuses?.thisWeek?.amount}</td>
                          
                        </tr>
                        <tr>
                        <td className="text-center">Last Week</td>
                         <td className="text-center">{registeredUserData?.last10Bonuses?.lastWeek?.count}</td>
                         <td className="text-center">{registeredUserData?.last10Bonuses?.lastWeek?.amount}</td>
                         
                       </tr>
                       <tr>
                       <td className="text-center">This Month</td>
                        <td className="text-center">{registeredUserData?.last10Bonuses?.thisMonth?.count}</td>
                        <td className="text-center">{registeredUserData?.last10Bonuses?.thisMonth?.amount}</td>
                        
                      </tr>
                      <tr>
                      <td className="text-center">Last Month</td>
                       <td className="text-center">{registeredUserData?.last10Bonuses?.lastMonth?.count}</td>
                       <td className="text-center">{registeredUserData?.last10Bonuses?.lastMonth?.amount}</td>
                       
                     </tr>
                     </>
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <span>You have no data in this time period.</span>
                        </td>
                      </tr>
                    )}
                </Table>
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
        </div>
        <div class="section col-2 panel" id="section_2">

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>VIP Cash Bonus</Accordion.Header>
              <Accordion.Body>
              <Table className="table-view">
                  <thead>
                    <tr>
                      <th className="" scope="col">
                       Period
                      </th>
                      <th className="text-center" scope="col">
                       Count
                      </th>
                      <th className="text-center" scope="col">
                       Amount
                      </th>
                     
                    </tr>
                  </thead>
                  
                  {registeredUserData?.last10VipCashBonuses  ? (
                    <>
                  <tr>
                           <td className="text-center">Today</td>
                            <td className="text-center">{registeredUserData?.last10VipCashBonuses?.today?.count}</td>
                            <td className="text-center">{registeredUserData?.last10VipCashBonuses?.today?.amount}</td>
                            
                          </tr>
                          <tr>
                          <td className="text-center">Yesterday</td>
                           <td className="text-center">{registeredUserData?.last10VipCashBonuses?.yesterday?.count}</td>
                           <td className="text-center">{registeredUserData?.last10VipCashBonuses?.yesterday?.amount}</td>
                           
                         </tr>
                         <tr>
                         <td className="text-center">This Week</td>
                          <td className="text-center">{registeredUserData?.last10VipCashBonuses?.thisWeek?.count}</td>
                          <td className="text-center">{registeredUserData?.last10VipCashBonuses?.thisWeek?.amount}</td>
                          
                        </tr>
                        <tr>
                        <td className="text-center">Last Week</td>
                         <td className="text-center">{registeredUserData?.last10VipCashBonuses?.lastWeek?.count}</td>
                         <td className="text-center">{registeredUserData?.last10VipCashBonuses?.lastWeek?.amount}</td>
                         
                       </tr>
                       <tr>
                       <td className="text-center">This Month</td>
                        <td className="text-center">{registeredUserData?.last10VipCashBonuses?.thisMonth?.count}</td>
                        <td className="text-center">{registeredUserData?.last10VipCashBonuses?.thisMonth?.amount}</td>
                        
                      </tr>
                      <tr>
                      <td className="text-center">Last Month</td>
                       <td className="text-center">{registeredUserData?.last10VipCashBonuses?.lastMonth?.count}</td>
                       <td className="text-center">{registeredUserData?.last10VipCashBonuses?.lastMonth?.amount}</td>
                       
                     </tr>
                     </>
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <span>You have no data in this time period.</span>
                        </td>
                      </tr>
                    )}
                </Table>
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
        </div>
      </div>
      <div class="col-wrap">
        <div class="section col-2 panel" id="section_2">

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Referral Commission</Accordion.Header>
              <Accordion.Body>
              <Table className="table-view">
                  <thead>
                    <tr>
                      <th className="" scope="col">
                       Period
                      </th>
                      <th className="text-center" scope="col">
                       Count
                      </th>
                      <th className="text-center" scope="col">
                       Amount
                      </th>
                     
                    </tr>
                  </thead>
                  
                  {registeredUserData?.last10ReferralCommissions  ? (
                    <>
                  <tr>
                           <td className="text-center">Today</td>
                            <td className="text-center">{registeredUserData?.last10ReferralCommissions?.today?.count}</td>
                            <td className="text-center">{registeredUserData?.last10ReferralCommissions?.today?.amount}</td>
                            
                          </tr>
                          <tr>
                          <td className="text-center">Yesterday</td>
                           <td className="text-center">{registeredUserData?.last10ReferralCommissions?.yesterday?.count}</td>
                           <td className="text-center">{registeredUserData?.last10ReferralCommissions?.yesterday?.amount}</td>
                           
                         </tr>
                         <tr>
                         <td className="text-center">This Week</td>
                          <td className="text-center">{registeredUserData?.last10ReferralCommissions?.thisWeek?.count}</td>
                          <td className="text-center">{registeredUserData?.last10ReferralCommissions?.thisWeek?.amount}</td>
                          
                        </tr>
                        <tr>
                        <td className="text-center">Last Week</td>
                         <td className="text-center">{registeredUserData?.last10ReferralCommissions?.lastWeek?.count}</td>
                         <td className="text-center">{registeredUserData?.last10ReferralCommissions?.lastWeek?.amount}</td>
                         
                       </tr>
                       <tr>
                       <td className="text-center">This Month</td>
                        <td className="text-center">{registeredUserData?.last10ReferralCommissions?.thisMonth?.count}</td>
                        <td className="text-center">{registeredUserData?.last10ReferralCommissions?.thisMonth?.amount}</td>
                        
                      </tr>
                      <tr>
                      <td className="text-center">Last Month</td>
                       <td className="text-center">{registeredUserData?.last10ReferralCommissions?.lastMonth?.count}</td>
                       <td className="text-center">{registeredUserData?.last10ReferralCommissions?.lastMonth?.amount}</td>
                       
                     </tr>
                     </>
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <span>You have no data in this time period.</span>
                        </td>
                      </tr>
                    )}
                </Table>
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
        </div>
        <div class="section col-2 panel" id="section_2">

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Turnover</Accordion.Header>
              <Accordion.Body>
              <Table className="table-view">
                  <thead>
                    <tr>
                      <th className="" scope="col">
                       Period
                      </th>
                      <th className="text-center" scope="col">
                       Count
                      </th>
                      <th className="text-center" scope="col">
                       Amount
                      </th>
                     
                    </tr>
                  </thead>
                  
                  {registeredUserData?.last10Turnovers  ? (
                    <>
                  <tr>
                           <td className="text-center">Today</td>
                            <td className="text-center">{registeredUserData?.last10Turnovers?.today?.count}</td>
                            <td className="text-center">{registeredUserData?.last10Turnovers?.today?.amount}</td>
                            
                          </tr>
                          <tr>
                          <td className="text-center">Yesterday</td>
                           <td className="text-center">{registeredUserData?.last10Turnovers?.yesterday?.count}</td>
                           <td className="text-center">{registeredUserData?.last10Turnovers?.yesterday?.amount}</td>
                           
                         </tr>
                         <tr>
                         <td className="text-center">This Week</td>
                          <td className="text-center">{registeredUserData?.last10Turnovers?.thisWeek?.count}</td>
                          <td className="text-center">{registeredUserData?.last10Turnovers?.thisWeek?.amount}</td>
                          
                        </tr>
                        <tr>
                        <td className="text-center">Last Week</td>
                         <td className="text-center">{registeredUserData?.last10Turnovers?.lastWeek?.count}</td>
                         <td className="text-center">{registeredUserData?.last10Turnovers?.lastWeek?.amount}</td>
                         
                       </tr>
                       <tr>
                       <td className="text-center">This Month</td>
                        <td className="text-center">{registeredUserData?.last10Turnovers?.thisMonth?.count}</td>
                        <td className="text-center">{registeredUserData?.last10Turnovers?.thisMonth?.amount}</td>
                        
                      </tr>
                      <tr>
                      <td className="text-center">Last Month</td>
                       <td className="text-center">{registeredUserData?.last10Turnovers?.lastMonth?.count}</td>
                       <td className="text-center">{registeredUserData?.last10Turnovers?.lastMonth?.amount}</td>
                       
                     </tr>
                     </>
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <span>You have no data in this time period.</span>
                        </td>
                      </tr>
                    )}
                </Table>
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
        </div>
      </div>
      <div class="col-wrap">
        <div class="section col-1 panel" id="section_2">

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Affiliate Profit & Loss</Accordion.Header>
              <Accordion.Body>
              <Table className="table-view">
                  <thead>
                    <tr>
                      <th className="" scope="col">
                       Period
                      </th>
                      <th className="text-center" scope="col">
                       Count
                      </th>
                      <th className="text-center" scope="col">
                       Amount
                      </th>
                     
                    </tr>
                  </thead>
                  
                  {registeredUserData?.last10AffiliateProfitLoss  ? (
                    <>
                  <tr>
                           <td className="text-center">Today</td>
                            <td className="text-center">{registeredUserData?.last10AffiliateProfitLoss?.today?.count}</td>
                            <td className="text-center">{registeredUserData?.last10AffiliateProfitLoss?.today?.amount}</td>
                            
                          </tr>
                          <tr>
                          <td className="text-center">Yesterday</td>
                           <td className="text-center">{registeredUserData?.last10AffiliateProfitLoss?.yesterday?.count}</td>
                           <td className="text-center">{registeredUserData?.last10AffiliateProfitLoss?.yesterday?.amount}</td>
                           
                         </tr>
                         <tr>
                         <td className="text-center">This Week</td>
                          <td className="text-center">{registeredUserData?.last10AffiliateProfitLoss?.thisWeek?.count}</td>
                          <td className="text-center">{registeredUserData?.last10AffiliateProfitLoss?.thisWeek?.amount}</td>
                          
                        </tr>
                        <tr>
                        <td className="text-center">Last Week</td>
                         <td className="text-center">{registeredUserData?.last10AffiliateProfitLoss?.lastWeek?.count}</td>
                         <td className="text-center">{registeredUserData?.last10AffiliateProfitLoss?.lastWeek?.amount}</td>
                         
                       </tr>
                       <tr>
                       <td className="text-center">This Month</td>
                        <td className="text-center">{registeredUserData?.last10AffiliateProfitLoss?.thisMonth?.count}</td>
                        <td className="text-center">{registeredUserData?.last10AffiliateProfitLoss?.thisMonth?.amount}</td>
                        
                      </tr>
                      <tr>
                      <td className="text-center">Last Month</td>
                       <td className="text-center">{registeredUserData?.last10AffiliateProfitLoss?.lastMonth?.count}</td>
                       <td className="text-center">{registeredUserData?.last10AffiliateProfitLoss?.lastMonth?.amount}</td>
                       
                     </tr>
                     </>
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <span>You have no data in this time period.</span>
                        </td>
                      </tr>
                    )}
                </Table>
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
        </div>
    
      </div>

    </div>
  );
};

export default Dashboard;
